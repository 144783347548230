function formatNumber(number) {
    return number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

export function calculateLoanPayments(amount, annualRate, months) {
    
    // Handle the case when the annual rate is 0
    if (annualRate === 0) {
        const monthlyPayment = amount / months;
        const paymentSchedule = [];
        let remainingBalance = amount;

        for (let month = 1; month <= months; month++) {
            const principalPayment = monthlyPayment;
            remainingBalance -= principalPayment;
            paymentSchedule.push({
                Month: month,
                "Monthly Payment": `֏ ${formatNumber(monthlyPayment)}`,
                "Principal Payment": `֏ ${formatNumber(principalPayment)}`,
                "Interest Payment": `֏ 0.00`,
                "Remaining Balance": `֏ ${formatNumber(remainingBalance)}`
            });
        }

        return paymentSchedule;
    }

    // Convert annual rate to monthly rate
    const monthlyRate = annualRate / 12 / 100;
    // Calculate monthly payment using the amortization formula
    const monthlyPayment = (amount * monthlyRate * Math.pow(1 + monthlyRate, months)) / (Math.pow(1 + monthlyRate, months) - 1);

    // Create the payment schedule
    const paymentSchedule = [];
    let remainingBalance = amount ?? '';

    for (let month = 1; month <= months; month++) {
        const interestPayment = remainingBalance * monthlyRate;
        const principalPayment = monthlyPayment - interestPayment;
        remainingBalance -= principalPayment;
        paymentSchedule.push({
            Month: month,
            "Monthly Payment": `֏ ${formatNumber(monthlyPayment)}`,
            "Principal Payment": `֏ ${formatNumber(principalPayment)}`,
            "Interest Payment": `֏ ${formatNumber(interestPayment)}`,
            "Remaining Balance": `֏ ${formatNumber(remainingBalance)}`
        });
    }

    return paymentSchedule;
}

// Example usage
const amount = 10000;  // Loan amount
const annualRate = 5;  // Annual interest rate in percent
const months = 24;  // Loan duration in months

const paymentSchedule = calculateLoanPayments(amount, annualRate, months);

// Display the payment schedule
console.log(paymentSchedule);
