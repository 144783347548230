export const columns = [
    {
      title: 'Ամիս',
      dataIndex: 'Month',
      key: 'name',
    },
    {
      title: 'Ամսական վճարում',
      dataIndex: 'Monthly Payment',
      key: 'age',
    },
    {
      title: 'Հիմնական վճարում',
      dataIndex: 'Principal Payment',
      key: '3',
      render(text) {
        return {
          props: {
            style: { color: "green"}
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Տոկոսների վճարում',
      dataIndex: 'Interest Payment',
      key: '2',
      render(text) {
        return {
          props: {
            style: { color: "red"}
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Մնացորդ',
      dataIndex: 'Remaining Balance',
      key: '1',
      render(text) {
        return {
          props: {
            style: { fontWeight: "bold"}
          },
          children: <div>{text}</div>
        };
      }
    },
  ];

  export const typeOptions = [
    { value: "month", label: "Ամիս" },
    { value: "year", label: "Տարի" }
  ];

  export const amountOptions = [
    { value: "1150000", label: "3.4 ԿՎ - 1․150․000֏" },
    { value: "1650000", label: "5.1 ԿՎ - 1․650․000֏" },
    { value: "2340000", label: "8 ԿՎ - 2․340․000֏" },
    { value: "2850000", label: "10.2 ԿՎ - 2․850․000֏" },
  ];

  export const annualRateOptions = [
    { value: "0", label: "Սահմանամերձ/Բարձր լեռնային - 0%" },
    { value: "3", label: "Քաղաք - 3%" },
    { value: "2", label: "Գյուղ - 0-2%" },
    { value: "5", label: "Երևան - 5%" },
  ];