import React, { useState } from 'react';
import { Button, Form, Col, Flex, InputNumber, Select, Space, Row, Table } from 'antd';
import { CalculatorFilled } from '@ant-design/icons';
import { columns, typeOptions, amountOptions, annualRateOptions } from './constants';
import {calculateLoanPayments} from "./utils";
const { Option } = Select;

const Calculator = () => {
  const [form] = Form.useForm();

  const [tableData, setTableData] = useState([])

  const onFinish = (values) => {
    const months = values.type === "year" ? 12 * Number(values.months) : + values.months 
    setTableData(calculateLoanPayments(+values.amount, +values.annualRate, months))
  };

  return (
    <div className='container-fluid'>
      <h1 className='text-center mb-5'>
        Վարկի հաշվիչ
      </h1>
      <Form
        form={form}
        name="control-hooks"
        onFinish={onFinish}
      >
        <Flex style={{height: 'conte'}} justify="center" align="center" horizontal wrap gap="middle">
        <Form.Item style={{ flex: '1 1', minWidth: '200px', height: '75px' }} layout='vertical' name="amount" label="Ընտրեք Փաթեթը" rules={[{ required: true, message: "Պարտադիր դաշտ"}]}>
              <Select
                allowClear
                size="large"
                options={amountOptions}
              />
            </Form.Item>
            <Form.Item style={{ flex: '1 1', minWidth: '200px', height: '75px' }} layout='vertical' name="annualRate" label="Ընտրեք բնակավայրը" rules={[{ required: true, message: "Պարտադիր դաշտ" }]}>
            <Select
              allowClear
              size="large"
              options={annualRateOptions}
            />
          </Form.Item>
          <Form.Item style={{ flex: '1 1', minWidth: '200px', height: '75px' }} layout='vertical' name="months" label="ՎԱՐԿԻ ԺԱՄԿԵՏ" rules={[{ required: true, message: "Պարտադիր դաշտ" }]} initialValue="12">
            <InputNumber
            style={{ width: '100%'}}
            size="large"
             addonAfter={
              <Form.Item name="type" noStyle initialValue="month">
                <Select options={typeOptions} />
              </Form.Item>
              }
            />
          </Form.Item>
        </Flex>
        <Flex style={{ marginTop: '16px' }} justify="center" align="center" horizontal>
          <Form.Item>
            <Space>
              <Button block size="large" style={{width: '200px'}} icon={<CalculatorFilled />} type="primary" htmlType="submit">
                Հաշվել
              </Button>
            </Space>
          </Form.Item>
        </Flex>
        
      </Form>
      {tableData?.length ? <Table dataSource={tableData} columns={columns}  /> : null}
    </div>
  );
};

export default Calculator;